import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_LOCATION } from 'Consts/apiResources';
import { ADMIN_LOCATIONS_MANAGE } from 'Consts/routes';
import { LOCATION_TYPES } from 'Consts/locations';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelLocationsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            setEnabledStatus: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        if (data) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
    })

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...formState,
            type: fromSelectObject(formState.type),
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_LOCATIONS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_LOCATION].id }
                    )
                );
            });
    }
    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
            type: fromSelectObject(formState.type),
        });
    }

    render() {
        const { data, location, history, actions } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-locations-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data && data.id),
                        label: 'id',
                        value: formState.id,
                    }]}
                    controls={[{
                        visible: Boolean(data && data.id && data.enabled),
                        title: 'Wyłącz lokację',
                        subtitle: 'Lokacja nie będzie dostępna dla wydarzeń',
                        buttonProps: {
                            onClick: () => actions.setEnabledStatus({ id: data && data.id, enabled: false }),
                            children: 'Wyłącz',
                        },
                    }, {
                        visible: Boolean(data && data.id && !data.enabled),
                        title: 'Włącz lokację',
                        subtitle: 'Lokacja będzie dostępna dla wydarzeń',
                        buttonProps: {
                            onClick: () => actions.setEnabledStatus({ id: data && data.id, enabled: true }),
                            children: 'Włącz',
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa',
                        }, {
                            type: 'select',
                            name: 'type',
                            label: 'Typ lokacji',
                            options: LOCATION_TYPES.map(locationType => ({
                                value: locationType.key,
                                label: locationType.label,
                            })),
                        }, {
                            type: 'input',
                            name: 'city',
                            label: 'Miasto',
                        }, {
                            type: 'input',
                            name: 'address',
                            label: 'Adres',
                        }, {
                            type: 'input',
                            name: 'userLimit',
                            label: 'Dopuszczalna ilość ludzi w lokacji w tym samym momencie (0 - brak limitu)',
                            inputProps: {
                                type: 'number',
                                placeholder: 'Brak limitu',
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}